import { useState } from 'react';
import { AdModel } from '../../Models/AdModel';
import { CardContainerComponent } from '../../RTFramework/Components/Card/CardComponent';
import "./Card.css";

export interface CardProps {
  cardItem: AdModel;
  onClick: () => void; // Funzione chiamata al click
}

interface CardContainerProps {
  listCard: AdModel[];
  onClick?: () => void; // Funzione chiamata al click
}

const baseImgUrl = (process.env.REACT_APP_PATH_IMG as string);

function Card({ cardItem, onClick }: CardProps) {
  const [isHovered, setIsHovered] = useState(false);

  const toggleOverlay = () => {
    setIsHovered(!isHovered);
  };
  
  return (
      <a 
        href={'/Immobile?Home=' + cardItem.Id} 
        onClick={(e) => {
          onClick();
        }} 
        aria-label={`Visualizza dettagli di ${cardItem.Titolo}, prezzo: ${new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(cardItem.Prezzo)}`}
      >
        <div className="card card-item">
          <div className="card-item--container-img" onMouseEnter={toggleOverlay} onMouseLeave={toggleOverlay}>
            <img className="card-img-top" src={cardItem.Imgs[0] != undefined ? baseImgUrl + cardItem.Imgs[0].Src : ""} alt={"Immagine di " + cardItem.Titolo} />
            {isHovered && (
              <div className="card-item--overlay-img" aria-hidden="true"></div>
            )}
          </div>
          <div className="card-body">
            <h5 className="card-title">{cardItem.Titolo}</h5>
          </div>
          <div className="card-tag-left card-price">
            <h5 /*className={cardItem.TrattativaRiservata ? " satinato" : ""}*/>{cardItem.TrattativaRiservata ? "Riservato" : new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 }).format(/*cardItem.TrattativaRiservata ? 1000 :*/ cardItem?.Prezzo ?? 0)}</h5>
          </div>
          {/*<div className="card-tag-left card-type">
            <h5>{cardItem.Type}</h5>
          </div>*/}
          {/*<div className="card-tag-right card-surface">
            <h5>{cardItem.Metratura} m²</h5>
          </div>*/}
          <div className="card-tag-right card-room">
            <h5>{/*cardItem.Vani} Locali*/}{cardItem.Type}</h5>
          </div>
        </div>
      </a>
  );
}

export function CardWrapperComponent({ listCard, onClick = () => {} }: CardContainerProps) {
  const cards = listCard.map((card, index) => <Card key={index} cardItem={card} onClick={onClick} />);

  return (
    <div>
      <CardContainerComponent listCard={cards} noElementMessage='Nessun elemento rispetta i criteri di ricerca' waitTime={1000}/>
    </div>
  )
}
